import React, { Component } from "react";
import HeaderMenu from "./HeaderMenu";
import Topbar from "./Topbar";
import { useState,useEffect } from "react";
import getBaseUrl from "../../base/htttp";
import $ from "jquery";
import 'szmigiel-meanmenu/jquery.meanmenu.min'

function Header1() {
  const [topbar, setTopbar] = useState([]);
  const [ls, setLs] = useState([]);
  const [scrolled,setScrolled]=React.useState(false);

  const handleScroll=() => {
    const offset=window.scrollY;
    if(offset > 200 ){
      setScrolled(true);
    }
    else{
      setScrolled(false);
    }
  }
    useEffect(() => {
        fetch(getBaseUrl()+"topbar",{method:'POST'})        
            .then((response) => response.json())
            .then((result) => {
                setTopbar(result.data.content);
                setLs(result.data.ls);
            });        
    }, []);

    return (
      <>
        <header className="header-area header-three">
          <Topbar htmldata={topbar}/>
          <div id="header-sticky" className={`menu-area  ${scrolled ? 'sticky-menu' : ''}`}>
            <div className="container">
              <div className="second-menu">
                <div className="row align-items-center">
                  <div className="col-xl-3 col-lg-3 d-flex">
                    <div className="logo">
                      <a href="/"><img src={ls.logo_url} alt="Bellfield Logo" /></a>
                    </div>
                    <div className="logo-text">
                      <h1>Bellfield <span>College</span></h1>
                      <p>Wisdom Through Faith and Knowledge</p>
                    </div>
                  </div>
                  <div className="col-xl-9 col-lg-9">
                    <div className="main-menu text-right text-xl-right">
                      <HeaderMenu />
                    </div>
                  </div>
                  <div className="col-12">
                    <div className="mobile-menu"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </header>
      </>
    )
  }

export default Header1;