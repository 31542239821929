import axios from "axios";
var url;
switch (process.env.NODE_ENV) {
    case 'production':
        url = 'https://demo.xpansion.com.au/bellfieldapp/api/v1/';
        break;
    case 'development':
        url ="http://127.0.0.1:8000/api/v1/";
        //url = 'https://demo.xpansion.com.au/bellfieldapp/api/v1/';
        break;
    default:
        url = 'https://google.com';
}

const getBaseUrl = () => {    
    return url;
}

const getAxiosBase = () => {    
    axios.create({
        baseURL: url, // replace with your API base URL
        timeout: 10000, // specify a timeout if needed
        headers: {
          'Content-Type': 'application/json',
          // Add any other headers you need for your API requests
        },
    });
}


export{getAxiosBase};
export default getBaseUrl;